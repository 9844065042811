import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any;
  constructor(
    private _router: Router
  ) { }

  setLocalStorage(login_data: any) {
    this.user = login_data;
    const local_storage_data = localStorage.getItem('user');
    if(local_storage_data) {
      localStorage.removeItem('user');
    }
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  logout() {
    this.clearLocalStorage();
    this._router.navigate(['/login']);
  }

  redirectToDashboard() {
    if(this.user || localStorage.getItem('user')) {
      this._router.navigate(['']);
    }
  }

  clearLocalStorage() {
    if(this.user || localStorage.getItem('user')) {
      this.user = null;
      localStorage.removeItem('user');
    }
  }

}
